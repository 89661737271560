<template>
  <div>
    <van-nav-bar title="我的" />
    <div class="top">
      <div class="top__headImg"></div>
      <div class="top__name">{{ userInfo.nickname }}</div>
      <div class="top__role">{{ role.name }}</div>
    </div>
    <div class="bottom" v-if="role.roleId === 3">
      <div class="bottom__title">负责片区</div>
      <div>
        <span
          class="bottom__tag"
          v-for="(item, index) in area"
          :key="item.areaId"
          >{{ item.name }}<span v-if="index < area.length - 1">、</span></span
        >
      </div>
    </div>
    <div class="noData" v-if="role.roleId !== 3">
      <van-empty description="暂无信息" />
    </div>
    <tabbar></tabbar>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import tabbar from "@/components/TabBar";
export default {
  components: { tabbar },
  data() {
    return {
      userInfo: {},
      role: {},
      area: [],
    };
  },
  created() {
    this.userInfo = storage.get("loginUserInfo");
    this.$store.dispatch("getAreaList").then((res) => {
      this.area = res;
      this.role = this.$store.state.area.role;
    });
  },
};
</script>
<style lang="scss" scoped>
.top {
  padding: 20px 0px;
  padding-bottom: 60px;
  background-image: url("../../assets/bg.jpg");
}
.top__headImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 20px;
  background-image: url("../../assets/logo.png");
  background-position: center;
  background-size: 100%;
}
.top__name {
  color: #fff;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.top__role {
  color: #999;
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.bottom {
  margin: 20px;
  background-color: #fff;
  margin-top: -40px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.bottom__title {
  margin-bottom: 10px;
}
.bottom__tag {
  color: #999;
  font-size: 12px;
}
</style>
